import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { LoggerService } from '@dougs/core/logger';
import { CouponHttpService } from '../http/coupon.http';

@Injectable({
  providedIn: 'root',
})
export class CouponService {
  constructor(
    private readonly httpService: CouponHttpService,
    private readonly logger: LoggerService,
  ) {}

  async addCouponFromUser(code: string): Promise<void> {
    try {
      await lastValueFrom(this.httpService.createCouponFromUser(code));
    } catch (e) {
      this.logger.error(e);
    }
  }

  async addCouponFromAdmin(code: string, userId: number): Promise<void> {
    try {
      await lastValueFrom(this.httpService.createCouponFromAdmin(code, userId));
    } catch (e) {
      this.logger.error(e);
    }
  }
}
